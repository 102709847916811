import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import './Table_for_all.css'


const columns = [
  { field: 'order_id', headerName: 'Order ID', width: 160,
    renderCell: (params) => (
      <Link to={`/order/${params.value}`} className='custom_link'>
        {params.value}
      </Link>
    )
   },
  {
    field: 'created_at',
    headerName: 'Creation time',
    width: 220,
  },
  {
    field: 'first_name',
    headerName: 'First name',
    width: 160,
    editable: false,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    width: 160,
    editable: false,
  },
  {
    field: 'contact_email',
    headerName: 'Email',
    width: 160,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Order Status',
    width: 160,
    editable: false,
  },
  {
    field: 'result',
    headerName: 'Result',
    width: 160,
    editable: false,
    renderCell: (params) => {
      let content;
      if (params.value === 'next_step') {
        content =  <div className="next-step">next step</div>;
      } else if (params.value === 'mistake') {
        content =  <div className="mistake">mistake</div>;
      }else if (params.value === 'retake') {
        content =  <div className="mistake">resend</div>;
      }else{
        content = <div className="mistake">retake</div>;
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {content}
          <Link to={`/order/${params.row.order_id}`} style={{ marginLeft: '10px' }}>
          <button style={{
              background: 'white',
              color: 'orange',
              border: 'none',
              borderRadius: '5px', 
              padding: '5px 10px',
              cursor: 'pointer', 
              outline: 'none'
            }}>
              View
            </button>
          </Link>
        </div>
      );
    }
  }
];

export default function Table() {
    const [rows, setRows] = useState([]);
  
    const fetchData = useCallback(async () => {
      try {
        const response = await axios.get(`${config.BACKEND_ENDPOINT}/customers`);
        console.log(response.data);
        setRows(response.data);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    }, []);
  
    useEffect(() => {
      fetchData();
    }, [fetchData]);
  
    return (
      <div className="page-container">
        <div className='sectionheader'>
                <h1>Pending orders</h1>
        </div>
        <span className='p1'> View Your Projects Here </span>
        <Box sx={{ height: 400, width: '100%', overflowX: 'auto' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              getRowId={(row) => row.order_id}
              GridRowCount = {1}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5]}
            />
        </Box>
      </div>
    );
  }
import React,{useState} from 'react';
import { Select, Button,Input,Row, Col } from 'antd';
import styles from './StatusForm.module.css';
import { useGlobalState } from '../../context/GlobalStateContext';
import {Spin} from "antd";
const { Option } = Select;
const { TextArea } = Input;

const StatusForm = ({ onClose,onConfirmed }) => {
    const globalState = useGlobalState();
    const [selectedStatus, setSelectedStatus] = useState(globalState.NEXT_STEP);
    const [feedback, setFeedback] = useState('');
    const [address_1, setAddress_1] = useState('');
    const [address_2, setAddress_2] = useState('');
    const [City, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [feedBackerror,setFeedBackError] = useState('');
    const [changeAddresserror,setchangeAddresserror] = useState('');
    const [loading,setLoading] = useState(false);
    const handleStatusChange = value => {
        setSelectedStatus(value);
        setFeedBackError('');
        setchangeAddresserror('');
    };

    return (
        <Spin spinning={loading}>
            <div className={styles.container}>
            <div className={styles.formGroup}>
                <label className={styles.label} htmlFor="status">Status</label>
                <Select defaultValue={globalState.NEXT_STEP} className={styles.select} onChange={handleStatusChange}>
                    <Option value={globalState.NEXT_STEP}>Next step</Option>
                    <Option value={globalState.MISTAKE}>Retake</Option>
                    <Option value={globalState.RESEND}>Change Address</Option>
                    <Option value={globalState.RETAKE}>Resend Impression</Option>
                    <Option value={globalState.CANCEL}>Cancel</Option>
                </Select>
            </div>
            {(selectedStatus !== globalState.NEXT_STEP)&&
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="status">Feedback</label>
                    <div className={styles.feedBackcontainer}>
                        <TextArea rows={4} placeholder="The impression has bubbles, affecting the accuracy of the aligner production, requires rework."  onChange={e => setFeedback(e.target.value)} />
                        {feedBackerror && <p style={{ color: 'red', fontSize: '12px' }}>{feedBackerror}</p>}
                    </div>
                    
                </div>
            }
            {(selectedStatus === globalState.RESEND)&&
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="status">Address</label>
                    <TextArea rows={1} placeholder="Address Line 1" autoSize onChange={e => setAddress_1(e.target.value)} />
                    <TextArea rows={1} placeholder="Address Line 2" autoSize onChange={e => setAddress_2(e.target.value)} />
                    <TextArea rows={1} placeholder="City" autoSize onChange={e => setCity(e.target.value)} />
                    <Row gutter={16}>
                        <Col span={8}>
                            <TextArea placeholder="Country" autoSize onChange={e => setCountry(e.target.value)} />
                        </Col>
                        <Col span={8}>
                            <TextArea placeholder="State" autoSize onChange={e => setState(e.target.value)}/>
                        </Col>
                        <Col span={8}>
                            <TextArea placeholder="Zip Code" autoSize onChange={e => setZip(e.target.value)}/>
                        </Col>
                    </Row>
                    {/* {changeAddresserror && <p style={{ color: 'red', fontSize: '12px' }}>{changeAddresserror}</p>} */}
                </div>
            }
            {changeAddresserror && <p style={{ color: 'red', fontSize: '12px' }}>{changeAddresserror}</p>}
            <div className={styles.buttonGroup}>
                <Button className={styles.cancelButton} onClick={onClose}>Cancel</Button>
                <Button type="primary" className={styles.confirmButton} onClick={() => onConfirmed(selectedStatus,feedback,address_1,
                    address_2,City,country,state,zip,setFeedBackError,setchangeAddresserror,setLoading
                )}>Confirm</Button>
            </div>
        </div>
        </Spin>
    );
};

export default StatusForm;
import React from 'react';
import { signout } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
    const dispatch = useDispatch();
    const handleLogOut = () =>{
        dispatch(signout());
        window.location.href = '/';
    }
    return (
        <header className="header">
        <div className="logoBox">
            <Link to="/console">
                <img src="/logo-2usmiles@2x.png" alt="2Usmiles Logo" className="centered-img" />
            </Link>
        </div>

        <div className="right-content">
            <button className="header-button" onClick={handleLogOut}>
                <span className='log-out'>Log Out</span>
            </button>
            <img src="/Dropdown.png" alt="Profile" className="profile-img"/>
        </div>
        </header>
    );
}


export default Header;

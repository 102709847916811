import React, { createContext, useContext } from 'react';

// 创建一个Context对象
const GlobalStateContext = createContext();

// Context提供者组件
export const GlobalStateProvider = ({ children }) => {
    const state = {
        ORDER_CONFIRMED: 'order_confirmed',
        IMPRESSION_SEND: 'impression_send',
        IMPRESSION_CHECK: 'impression_check',
        IN_PRODUCTION: 'in_production',
        PRODUCTION_SHIPPED: 'production_shipped',
        RESEND:'resend',
        MISTAKE: 'mistake',
        RETAKE:'retake',
        NEXT_STEP:'next_step',
        CANCEL:'cancel'
    };

    return (
        <GlobalStateContext.Provider value={state}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = () => useContext(GlobalStateContext);
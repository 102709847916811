import React from 'react';
import BasicTimeline from '../TimeLine/TimeLine';
import styles from './OrderStatus.module.css';

function OrderStatus({ details, setOrderDetails,id,setRetakeTimes,retakeTimes }) {
    return (
        <div className={styles.container}>
            <div className={styles.line}></div>
            <div className={styles.headerSection}>
                <div>
                    <h2>Order Status</h2>
                </div>
                <div className={styles.formSection}>
                    <div className={styles.section}>
                        <label className={styles.labelSecond}>Order Status</label>
                        <input className={`${styles.inputStyle} ${styles.readonly}`} value={details.status || ''} readOnly />
                    </div>
                    <div className={styles.sectionTwo}>
                        <BasicTimeline details={details} setOrderDetails={setOrderDetails} id = {id} setRetakeTimes = {setRetakeTimes} retakeTimes = {retakeTimes}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderStatus;
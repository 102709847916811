import React from 'react';
import styles from './OrderDetails.module.css';

function OrderDetails({ details = {} }) {
    // Provide default values for details properties
    const {
        first_name = '',
        last_name = '',
        order_id = '',
        contact_email = '',
        address1 = '',
        address2 = '',
        city = '',
        province = '',
        zip = '',
        country = ''
    } = details;

    return (
        <div className={styles.container}>
            <div className={styles.line}></div>
            <div className={styles.headerSection}>
                <div>
                    <h2>Basic Information</h2>
                </div>
                <div className={styles.formSection}>
                    <div className={styles.inputPair}>
                        <div className={styles.inputContainer}>
                            <label className={styles.label}>First Name</label>
                            <input className={`${styles.inputStyleOne} ${styles.readonly}`} value={first_name} readOnly />
                        </div>
                        <div className={styles.inputContainer}>
                            <label className={styles.label}>Last Name</label>
                            <input className={`${styles.inputStyleOne} ${styles.readonly}`} value={last_name} readOnly />
                        </div>
                    </div>
                    <div className={styles.section}>
                        <label className={styles.labelSecond}>Order ID</label>
                        <input className={`${styles.inputStyle} ${styles.readonly}`} value={order_id} readOnly />
                    </div>
                    <div className={styles.section}>
                        <label className={styles.labelSecond}>Email</label>
                        <input className={`${styles.inputStyle} ${styles.readonly}`} value={contact_email} readOnly />
                    </div>
                    <div className={styles.section}>
                        <label className={styles.labelSecond}>Address</label>
                        <input className={`${styles.inputStyle} ${styles.readonly}`} value={`${address1}${address2 ? " " + address2 : ""}, ${city}, ${province}, ${zip}, ${country}`} readOnly />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;

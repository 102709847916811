import React from 'react';
import styles from './PopupModal.module.css';  // Import CSS module
import { CloseOutlined } from '@ant-design/icons';
const PopupModal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.popupModal}>
            <div className={styles.popupContent}>
                <button className={styles.closeButton} onClick={onClose}><CloseOutlined style={{fontSize: '25px',color:'#98A2B3',marginLeft: '-50px', marginTop: '10px'}}/></button>
                <div className={styles.titleBox}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subtitle}>Feedback on this step.</div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default PopupModal;
import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Table from "../../Components/Table/Table_for_all";
import TableSearchResult from "../../Components/Table/Table_for_search";
import config from '../../config/config';
import "./SearchPage.css"
import axios from "axios";
const SearchPage =()=>{
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('order_id');
    const [onsearchMode, setOnsearchMode] = useState(false);
    const [rows, setRows] = useState([]);

    const clearSearch = () => {
        setSearchTerm('');
        setSearchType('order_id');
        setOnsearchMode(false);
    };

    const handleSearch = async (event) => {
        event.preventDefault();
        setOnsearchMode(true);
        try{
            const response = await axios.post(`${config.BACKEND_ENDPOINT}/search`, {
                searchType,
                searchTerm
            });
            const combinedData = [...response.data.inProgress.Items, ...response.data.completed.Items];
            setRows(combinedData);
        }catch (error) {
            setOnsearchMode(false);
            console.error('Failed to fetch data', error);
        }
    };
    return (
        <div>
            <Header/>
            <div className="search-container">
                <div className="logo-section">
                    <img src="/logo-2usmiles.png" alt="2U Smiles Logo" className="logo" />
                    <p>Search for user name, ID, etc.</p>
                </div>
                <form onSubmit={handleSearch} className="search-form">
                    <div className="input-group">
                        <select
                            value={searchType}
                            onChange={(e) => setSearchType(e.target.value)}
                            className="search-select"
                        >
                            <option value="order_id">Order ID</option>
                            <option value="first_name">First Name</option>
                            <option value="last_name">Last Name</option>
                            <option value="contact_email">Email</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Search Keyword"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    <button type="submit" className="search-button">
                        <div className="search-text">
                            Search
                        </div>
                    </button>
                </form>
        </div>
        <div className="table">
           {onsearchMode ? (
                <TableSearchResult rows={rows} searchType ={searchType} clearSearch = {clearSearch}/>
            ) : (
                <Table />
            )}
        </div> 
        </div>
    );

}

export default SearchPage;
import React from 'react';
import styles from './Tracking.module.css';

function Tracking({ details,retakeTimes}) {
    const generateLabelText = (baseText, retakeTimes) => {
        if (retakeTimes === 0) {
            return baseText;
        } else if (retakeTimes === 1) {
            return `1st ${baseText}`;
        } else if (retakeTimes === 2) {
            return `2nd ${baseText}`;
        } else if (retakeTimes === 3) {
            return `3rd ${baseText}`;
        } else {
            return `${retakeTimes}th ${baseText}`;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.line}></div>
            <div className={styles.headerSection}>
                <div>
                    <h2>Tracking Numbers</h2>
                </div>
                <div className={styles.formSection}>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelTracking}>
                            {generateLabelText("Impression Send Tracking Number", retakeTimes)}
                        </label>
                        <a href={details.impression_label_to_c_url || ""}
                            className={`${styles.inputStyleTwo} ${styles.readonly} ${styles.linkInput}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {details.impression_label_to_c_tracking_num || ""}
                        </a>
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelTracking}>Impression Return Tracking Number</label>
                        <a href={details.impression_label_form_c_url}
                            className={`${styles.inputStyleTwo} ${styles.readonly} ${styles.linkInput}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {details.impression_label_form_c_tracking_num}
                        </a>
                    </div>

                    <div className={styles.inputContainer}>
                        <label className={styles.labelTracking}>Send To Customer Tracking Number</label>
                        <a href={details.product_label_to_c_url}
                            className={`${styles.inputStyleTwo} ${styles.readonly} ${styles.linkInput}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {details.product_label_to_c_tracking_num}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tracking;
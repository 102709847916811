import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './Components/ProtectedRoute';
import LoginPage from './Page/LoginPage/LoginPage';
import SearchPage from './Page/SearchPage/SearchPage';
import OrderPage from './Page/OrderPage/OrderPage';
import { GlobalStateProvider } from './context/GlobalStateContext';
function App() {
  return (
    <GlobalStateProvider>
      <Router>
        <div className='App'>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/console" element={
                <ProtectedRoute>
                  <SearchPage />
                </ProtectedRoute>
              // <SearchPage />
            }/>
            <Route path="/order/:id" element={
              <ProtectedRoute>
                <OrderPage />
              </ProtectedRoute>
              // <OrderPage />
              } />
          </Routes>
        </div>
      </Router>
    </GlobalStateProvider>
  );
  }

export default App;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/config';
import Header from "../../Components/Header/Header";
import OrderDetails from '../../Components/OrderDetails/OrderDetails';
import HistoricalOrders from '../../Components/HistoricalOrders/HistoricalOrders';
import Tracking from '../../Components/Tracking/Tracking';
import OrderStatus from '../../Components/OrderStatus/OrderStatus';
import styles from './OrderPage.module.css';

function OrderPage() {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState({});
    const [retakeTimes, setRetakeTimes] = useState(0);
    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const response = await axios.post(`${config.BACKEND_ENDPOINT}/search`, {
                    searchType: "order_id",
                    searchTerm: id
                });
                setOrderDetails(response.data.completed.Items[0] ||response.data.inProgress.Items[0]);
            } catch (error) {
                console.error('Failed to fetch order details', error);
            }
        };
        fetchOrderDetails();
    }, [id,orderDetails]);

    return (
        <div>
            <Header />
            <div className={styles.orderInformation}>
                <h1>Order information</h1>
                <p>Check here the information of users who purchased 2usmiles products.</p>
            </div>
            <div className={styles.pageContainer}>
                <div className={styles.content}>
                    <OrderDetails details={orderDetails} />
                    <HistoricalOrders details={orderDetails} />
                    <Tracking details={orderDetails} retakeTimes ={retakeTimes}/>
                    <OrderStatus details={orderDetails} setOrderDetails = {setOrderDetails} id = {id} setRetakeTimes ={setRetakeTimes} retakeTimes ={retakeTimes}/>
                </div>            
            </div>
        </div>
    );
}

export default OrderPage;
import React, {useState,useEffect} from 'react';
import styles from './HistoricalOrders.module.css';
import axios from 'axios';
import config from '../../config/config';
function HistoricalOrders({ details = {}  }) {

    const [previousOrders, setpreviousOrders] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            if (!details.contact_email) {
                console.log('No contact email provided');
                return;
            }
            try {
                const response = await axios.post(`${config.BACKEND_ENDPOINT}/searchprevious`, {
                    searchType: "contact_email",
                    searchTerm: details.contact_email
                });
                if (response.data && response.data.Items) {
                    setpreviousOrders(response.data.Items);
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchData();
    }, [details.contact_email]);
    return (
        <div className={styles.container}>
            <div className={styles.line}></div>
            <div className={styles.headerSection}>
                <div>
                    <h2>Historical Orders</h2>
                </div>
                <div className={styles.formSection}>
                    {previousOrders.map((order, index) => (
                        <div key={index} className={styles.section}>
                            <label className={styles.labelThird}>
                                {new Date(order.created_at).toLocaleDateString("en-US", {
                                    year: 'numeric', month: 'short', day: 'numeric'
                                })} 
                            </label>
                            <input className={`${styles.inputStyle} ${styles.readonly}`} value={order.type} readOnly />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HistoricalOrders;
import React, { useState, useEffect,useCallback } from 'react';
import { Timeline, Button } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, RightCircleOutlined, IssuesCloseOutlined} from '@ant-design/icons';
import PopupModal from '../PopupWindow/PopupModal';
import StatusForm from '../PopupWindow/StatusForm';
import { useGlobalState } from '../../context/GlobalStateContext';
import axios from 'axios';
import config from '../../config/config';

export default function BasicTimeline({ details, setOrderDetails,id,setRetakeTimes }) {
  const [order_confirmed_time, setOrder_confirmed_time] = useState('');
  const [impression_send_time, setImpression_send_time] = useState('');
  const [impression_check_time, setImpression_check_time] = useState('');
  const [in_production_time, setIn_production_time] = useState('');
  const [production_shipped_time, setProduction_shipped_time] = useState('');
  const [order_confirmed_feedback, setOrder_confirmed_feedback] = useState('');
  const [impression_send_feedback, setImpression_send_feedback] = useState('');
  const [impression_check_feedback, setImpression_check_feedback] = useState('');
  const [in_production_feedback, setIn_production_feedback] = useState('');
  const [production_shipped_feedback, setProduction_shipped_feedback] = useState('');
  
  const [status, setStatus] = useState(details.status);
  const [result, setResult] = useState(details.result);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);

  const globalState = useGlobalState();

  const handleOpen = (step) => {
    setCurrentStep(step.title);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentStep(null);
  };

  const fetchTimelineData = useCallback (async() => {
    try {
      const response = await axios.post(`${config.BACKEND_ENDPOINT}/searchTimeLineInfo`, {
        searchType: "order_id",
        searchTerm: id
      });
      const items = response.data.Items[0];
      setOrder_confirmed_time(items.order_confirmed_time || '');
      setImpression_send_time(items.impression_send_time || '');
      setImpression_check_time(items.impression_check_time || '');
      setIn_production_time(items.in_production_time || '');
      setProduction_shipped_time(items.production_shipped_time || '');
      setOrder_confirmed_feedback(items.order_confirmed_feedback || '');
      setImpression_send_feedback(items.impression_send_feedback || '');
      setImpression_check_feedback(items.impression_check_feedback || '');
      setIn_production_feedback(items.in_production_feedback || '');
      setProduction_shipped_feedback(items.production_shipped_feedback || '');
      setRetakeTimes(items.retakeTimes || 0);
    } catch (error) {
      console.error('Error fetching timeline data:', error);
    }
  }, [id,
    setOrder_confirmed_time,
    setImpression_send_time,
    setImpression_check_time,
    setIn_production_time,
    setProduction_shipped_time,
    setOrder_confirmed_feedback,
    setImpression_send_feedback,
    setImpression_check_feedback,
    setIn_production_feedback,
    setProduction_shipped_feedback,
    setRetakeTimes,]);

  useEffect(() => {
    setStatus(details.status);
    setResult(details.result);
    fetchTimelineData();
  }, [details,fetchTimelineData]);

  const getNextStatus = () => {
    const statusOrder = [
      globalState.ORDER_CONFIRMED,
      globalState.IMPRESSION_SEND,
      globalState.IMPRESSION_CHECK,
      globalState.IN_PRODUCTION,
      globalState.PRODUCTION_SHIPPED,
      'completed'
    ];
    const currentIndex = statusOrder.indexOf(status);
    if (currentIndex >= 0 && currentIndex < statusOrder.length - 1) {
      return statusOrder[currentIndex + 1];
    }
    return status;
  };

  const updateAddress = async(address_1,address_2,City,country,state,zip)=>{
    try{
      const response = await axios.post(`${config.BACKEND_ENDPOINT}/updateAddress`, {
        primaryKeyValue: details.order_id,
        address_1,
        address_2,
        City,
        state,
        zip,
        country
      });
      if (response.status === 200) {
      } else {
        console.error('Failed to update status:', response.data);
      }
    }catch (error) {
      console.error('Error updating status:', error);
    }
  }

  const updatelabelAndRetakeTimes = async(select_result,address_1,address_2,City,country,state,zip)=>{
    try{
      const response = await axios.post(`${config.BACKEND_ENDPOINT}/updateRetakeTimeAndlabel`, {
        primaryKeyValue: id,
        type:select_result,
        address_1: address_1 || details.address1,
        address_2: address_2 || details.address2,
        City: City || details.city,
        country: country || details.country,
        state: state || details.province,
        zip: zip || details.zip,
        firstname: details.first_name,
        lastname: details.last_name,
        phone: details.phone,
        email: details.contact_email
      });
      if (response.status !== 200) {
        throw new Error('Failed to update status: ' + response.data);
      }
    }catch (error) {
      console.error('Error updating status:', error);
      throw error;
    }
  }

  const onConfirmed = async (select_result,feedback,address_1,
    address_2,City,country,state,zip,setFeedBackError,setchangeAddresserror,setLoading) => {
    try {
      setLoading(true);
      let newStatus = status;
      if (select_result === globalState.NEXT_STEP) {
        newStatus = getNextStatus();
      }else{
        if(feedback === ''){
          setFeedBackError('Please leave your feedback');
          setLoading(false);
          return;
        }
      }

      //update retake times and generate new label
      if(select_result !== globalState.NEXT_STEP && select_result !== globalState.CANCEL){
        try {
          await updatelabelAndRetakeTimes(select_result,address_1,address_2,City,country,state,zip);
        } catch (error) {
          setchangeAddresserror('Unable to create a new label. Please check the inputted new address.');
          setLoading(false);
          return;
        }
      }
      //update Address
      if(select_result === globalState.RESEND){
        await updateAddress(address_1,address_2,City,country,state,zip);
      }

      //update feedback
      let currentFeedback = '';
      if (select_result === globalState.NEXT_STEP) {
        const nextStep = steps.find(step => step.key === status);
        feedback =  nextStep.next_step_feed;
      }
      switch (status) {
        case globalState.ORDER_CONFIRMED:
          currentFeedback = order_confirmed_feedback;
          break;
        case globalState.IMPRESSION_SEND:
          currentFeedback = impression_send_feedback;
          break;
        case globalState.IMPRESSION_CHECK:
          currentFeedback = impression_check_feedback;
          break;
        case globalState.IN_PRODUCTION:
          currentFeedback = in_production_feedback;
          break;
        case globalState.PRODUCTION_SHIPPED:
          currentFeedback = production_shipped_feedback;
          break;
        default:
          break;
      }
      const updatedFeedback = currentFeedback ? `${feedback}\n\n${currentFeedback}` : feedback;
      const response2 = await axios.post(`${config.BACKEND_ENDPOINT}/updatefeedback`, {
        primaryKeyValue: details.order_id,
        type:status,
        feedback: updatedFeedback
      });
      
      const response = await axios.post(`${config.BACKEND_ENDPOINT}/updateStatus`, {
        primaryKeyValue: details.order_id,
        newStatus: newStatus,
        newResult: select_result
      });

      if (response.status === 200 && response2.status === 200) {
        if(newStatus !== 'completed' && select_result !== globalState.CANCEL){
          const data = response.data.data.Attributes;
          setStatus(data.status);
          setResult(data.result);
          setOrderDetails(data);
        }else{
          const data = response.data.data;
          setStatus(data.status);
          setResult(data.result);
          setOrderDetails(data);
        }
        setFeedBackError('');
        setchangeAddresserror('');
        setLoading(false);
        handleClose();
      } else {
        console.error('Failed to update status:', response.data," ",response2.data);
      }

      
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const steps = [
    { key: globalState.ORDER_CONFIRMED, title: 'Order Created', date: order_confirmed_time, 
      feedback: order_confirmed_feedback|| 'Order has been created', next_step_feed: 'Order has been created'},
    { key: globalState.IMPRESSION_SEND, title: 'Impression Shipped', date: impression_send_time, 
      feedback: impression_send_feedback || 'Confirm that the dental model has been sent',next_step_feed:'Confirm that the dental model has been sent'},
    { key: globalState.IMPRESSION_CHECK, title: 'Check Impression', date: impression_check_time, 
      feedback: impression_check_feedback || 'Confirm that the model has been received and meets the requirements',next_step_feed:'Confirm that the model has been received and meets the requirements'},
    { key: globalState.IN_PRODUCTION, title: 'In Production', date: in_production_time, 
      feedback: in_production_feedback  || 'In production and in transit',next_step_feed:'In production and in transit'},
    { key: globalState.PRODUCTION_SHIPPED, title: 'Production Shipping', date: production_shipped_time, 
      feedback: production_shipped_feedback || 'Being shipped to the customer',next_step_feed:'Being shipped to the customer'},
    {key: 'completed',title:'Completed',data: production_shipped_time,feedback: production_shipped_feedback || 'Completed',
      next_step_feed:'Completed'}
  ];

  const getIconForStep = (currentStep) => {
    if (status === currentStep) {
      if(status === 'completed'){
        return <CheckCircleOutlined style={{ color: 'green', fontSize: '24px' }} />;
      }
      if (result === globalState.NEXT_STEP) {
        return <RightCircleOutlined style={{ color: 'blue', fontSize: '24px' }} />;
      } else if (result === globalState.RESEND || result === globalState.MISTAKE || result === globalState.RETAKE) {
        return <IssuesCloseOutlined style={{ color: 'orange', fontSize: '24px' }} />;
      } else if(result === globalState.CANCEL){
        return <CloseCircleOutlined style={{ color: 'red', fontSize: '24px' }} />;
      }
      return <ClockCircleOutlined style={{ color: 'gray', fontSize: '24px' }} />;
    } else {
      const currentIndex = steps.findIndex(step => step.key === currentStep);
      const statusIndex = steps.findIndex(step => step.key === status);

      if (statusIndex === -1) {
        return <CloseCircleOutlined style={{ color: 'red', fontSize: '24px' }} />;
      }

      if (currentIndex > statusIndex) {
        return <ClockCircleOutlined style={{ color: 'gray', fontSize: '24px' }} />;
      } else {
        return <CheckCircleOutlined style={{ color: 'green', fontSize: '24px' }} />;
      }
    }
  };

  const shouldShowButton = (currentStep) => {
    const currentIndex = steps.findIndex(step => step.key === currentStep);
    const statusIndex = steps.findIndex(step => step.key === status);

    return (currentIndex === statusIndex && result === globalState.NEXT_STEP && status !== 'completed') ||
      (currentIndex === statusIndex && (result === globalState.RESEND || result === globalState.MISTAKE || result === globalState.RETAKE));
  };

  const renderTimelineEntries = (step) => {
    const {key, date, feedback } = step;
    const formattedDate = date ? new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : '';
    const feedbackShow = (status === key && result !== globalState.NEXT_STEP)?
        <p style={{ margin: 0, whiteSpace: 'pre-wrap', fontSize: '12px', flexGrow: 1,color: 'red' }}>
          {feedback || 'No feedback provided.'}
        </p>
        :<p style={{ margin: 0, whiteSpace: 'pre-wrap', fontSize: '12px', flexGrow: 1 }}>
            {feedback || 'No feedback provided.'}
          </p>;

    return (
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        {date && <p style={{ margin: 0, marginRight: '10px', fontSize: '12px' }}>{formattedDate}</p>}
        {date && (
          feedbackShow
        )}
      </div>
    );
  };

  const items = steps.map(step => ({
    dot: getIconForStep(step.key),
    children: (
      <div style={{ margin: '10px 0' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
          <h1 style={{ margin: 0, flexGrow: 1 }}>{step.title}</h1>
          {shouldShowButton(step.key) &&<Button type="ghost" onClick={() => handleOpen(step)} style={{
            marginLeft: '300px', 
            color: 'black',
            border: '1px solid #d9d9d9', 
            boxShadow: 'none', 
          }}>Edit</Button>}
        </div>
      <div style={{ marginTop: '10px' }}>
        {renderTimelineEntries(step)}
      </div>
    </div>
    )
  }));

  return (
    <>
      <Timeline items={items} />
      {currentStep && (
        <PopupModal isOpen={modalOpen} onClose={handleClose} title={currentStep}>
          <StatusForm onClose={handleClose} onConfirmed={onConfirmed} />
        </PopupModal>
      )}
    </>
  );
}

// const backendEndpointProduct = "http://localhost:3001";
// const frontendEndpointProduct = "http://localhost:3000";

const backendEndpointProduct = "https://backend.crm-2usmiles.com";
const frontendEndpointProduct = "https://www.crm-2usmiles.com";

const config = {
  BACKEND_ENDPOINT: backendEndpointProduct,
  FRONTEND_ENDPOINT: frontendEndpointProduct
};

export default config;

import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from './SignUp.module.css';
import { CloseOutlined } from '@ant-design/icons';
import { signup, clearAuthError } from '../../redux/actions/authActions';

const SignUp = ({ isOpen, onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [registrationCode, setRegistrationCode] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const SignUperror = useSelector((state) => state.auth.error);

    if (!isOpen) return null;

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setError('');
        dispatch(clearAuthError());
        dispatch(signup({ employeeId: username, emloyeePWD: password, registrationCode:registrationCode}));
    };

    const handleClose = () => {
        setError('');
        dispatch(clearAuthError());
        onClose();
    };

    const handleInputChange = (setterFunction, value) => {
        setError('');
        setterFunction(value);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={handleClose}>
                    <CloseOutlined style={{ fontSize: '20px', color: '#98A2B3' }} />
                </button>
                <div className={styles.titleBox}>
                    <div className={styles.title}>Sign up</div>
                    <div className={styles.subtitle}>Sign up to create your account.</div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={styles.inputField}>
                        <label className={styles.label}>Username</label>
                        <input
                            className={styles.input}
                            type="text"
                            value={username}
                            onChange={(e) => handleInputChange(setUsername, e.target.value)}
                            placeholder="Username"
                            required
                        />
                    </div>
                    <div className={styles.inputField}>
                        <label className={styles.label}>Password</label>
                        <input
                            className={styles.input}
                            type="password"
                            value={password}
                            onChange={(e) => handleInputChange(setPassword, e.target.value)}
                            placeholder="Password"
                            required
                        />
                    </div>
                    <div className={styles.inputField}>
                        <label className={styles.label}>Confirm Password</label>
                        <input
                            className={styles.input}
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => handleInputChange(setConfirmPassword, e.target.value)}
                            placeholder="Confirm Password"
                            required
                        />
                    </div>
                    <div className={styles.inputField}>
                        <label className={styles.label}>Registration Code</label>
                        <input
                            className={styles.input}
                            type="text"
                            value={registrationCode}
                            onChange={(e) => handleInputChange(setRegistrationCode, e.target.value)}
                            placeholder="Registration Code"
                            required
                        />
                    </div>
                    <button className={styles.submitButton} type="submit">Sign up</button>
                    {error && <div className={styles.error}>{error}</div>}
                    {SignUperror && <div className={styles.error}>{SignUperror}</div>}
                </form>
            </div>
        </div>
    );
};

export default SignUp;
